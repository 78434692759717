import {
  Sidebar,
  SidebarContent,
} from "components/LeadModal/components/Sidebar"
import DecisionTree from "components/LeadModal/components/DecisionTree"
import History from "components/LeadModal/components/History"
import Title from "components/Typography/Title"
import { useLeadModalContext } from "components/LeadModal/context"
import { SPECIAL_CHARS } from "components/LeadModal/components/CopyClipboard/constants"

const Consortium = () => {
  const { lead } = useLeadModalContext()

  return (
    <>
      <Sidebar
        title="Dados do cliente"
        name={lead.info.lead_name}
        document={lead.info.document_number}
        variant="full"
      >
        <SidebarContent
          variant="full"
          values={[
            {
              label: "E-mail",
              value: lead.info.email,
            },
            {
              label: "Telefone",
              specialChars: SPECIAL_CHARS.cellphone,
              value: [lead.info.phone_number, lead.info.phone_numbers],
            },
            {
              label: "Categoria",
              value: lead.info.category,
            },
            {
              label: "Valor do bem",
              value: lead.info.asset_value,
              specialChars: SPECIAL_CHARS.money,
            },
            {
              label: "Valor do crédito",
              value: lead.info.credit_value,
              specialChars: SPECIAL_CHARS.money,
            },
            {
              label: "Valor da parcela",
              value: lead.info.installment_value,
              specialChars: SPECIAL_CHARS.money,
            },
            {
              label: "Prazo",
              value: lead.info.installment_quantity,
            },
            {
              label: "Código do bem",
              value: lead.info.asset_code,
            },
            {
              label: "Fundo de reserva",
              value: lead.info.reserve_fund,
              specialChars: SPECIAL_CHARS.rate,
            },
            {
              label: "Taxa de administração",
              value: lead.info.admin_fee,
              specialChars: SPECIAL_CHARS.rate,
            },
          ]}
        />
        <SidebarContent
          variant="full"
          values={[
            {
              label: "Valor de carta (simulação)",
              value: lead.info.asset_initial_value,
              specialChars: SPECIAL_CHARS.money,
            },
            {
              label: "Valor de parcela (simulação)",
              value: lead.info.installment_initial_value,
              specialChars: SPECIAL_CHARS.money,
            },
            {
              label: "Urgência",
              value: lead.info.purchase_time,
            },
            {
              label: "Fluxo backup",
              value: lead.info.is_bypass,
            },
            {
              label: "Crédito disponível",
              value: lead.info.has_credit,
            },
          ]}
        />
        <SidebarContent
          variant="full"
          values={[
            {
              label: "Usuário",
              value: lead.info.user_owner_name,
            },
            {
              label: "Criado na origem em",
              value: lead.info.source_created_at,
            },
            {
              label: "Criado na Pivô em",
              value: lead.info.created_at,
            },
            {
              label: "Atribuído em",
              value: lead.info.user_assigned_at,
            },
            {
              label: "Expira em",
              value: lead.info.expired_at,
            },
          ]}
        />
      </Sidebar>
      <History records={lead.history} />
      <div className="modal-lead__tabs-wrapper">
        <div className="modal-lead__decision-tree">
          <Title variant="small">Registrar novo contato</Title>
          <DecisionTree />
        </div>
      </div>
    </>
  )
}

export default Consortium
