import { useCallback, useEffect, useReducer } from "react"

import services from "services"
import { INITIAL_STATE, Reducer } from "./reducer"
import { useLeadModalContext } from "components/LeadModal/context"

export const useAddFee = () => {
  const {
    lead: {
      info: { lead_uuid },
    },
    business: { business_id },
  } = useLeadModalContext()
  const [addFeeState, dispatch] = useReducer(Reducer, INITIAL_STATE)

  const onInsertClick = useCallback(() => {
    dispatch({
      type: "START_EDITING",
    })
  }, [])

  const fetchCompanies = useCallback(async () => {
    const data = await services.feeOfeer.company.list({
      business_id,
      lead_uuid,
    })

    dispatch({
      type: "FETCH_COMPANIES",
      payload: {
        data,
        business_id,
      },
    })
  }, [business_id, dispatch, lead_uuid])

  const fetchFees = useCallback(async () => {
    const data = await services.feeOfeer.fee.list({
      business_id,
      lead_uuid,
    })

    dispatch({
      type: "FETCH_FEES",
      payload: {
        data,
        business_id,
      },
    })
  }, [business_id, dispatch, lead_uuid])

  useEffect(() => {
    fetchCompanies()
  }, [fetchCompanies])

  useEffect(() => {
    fetchFees()
  }, [fetchFees])

  return {
    lead_uuid,
    business_id,
    onInsertClick,
    feeList: addFeeState.fees,
    isEditing: addFeeState.isEditing,
    reducer: { state: addFeeState, dispatch },
  }
}
