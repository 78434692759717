import * as Yup from "yup"
// import validators from "utils/validators"

export const values = {
  debit_rate: "",
  machine_rental: "",
  cash_credit_rate: "",
  anticipation_rate: "",
  custom_company_name: "",
  competitor_company_id: "",
  credit_rate_from_2_to_6: "",
  credit_rate_from_7_to_12: "",
}

export const validation = Yup.object().shape({
  debit_rate: Yup.string().optional(),
  machine_rental: Yup.string().optional(),
  cash_credit_rate: Yup.string().optional(),
  anticipation_rate: Yup.string().optional(),
  custom_company_name: Yup.string().optional(),
  credit_rate_from_2_to_6: Yup.string().optional(),
  credit_rate_from_7_to_12: Yup.string().optional(),
  competitor_company_id: Yup.string().required("Campo obrigatório"),
})

export default {
  values,
  validation,
}
