export const INITIAL_STATE = {
  fees: [],
  companies: [],
  isEditing: false,
  selectedFee: null,
}

export const Reducer = (state, action) => {
  switch (action.type) {
    case "RESET": {
      return {
        ...INITIAL_STATE,
      }
    }
    case "START_EDITING": {
      return {
        ...state,
        isEditing: true,
        selectedFee: action?.payload?.selectedFee || null,
      }
    }
    case "CANCEL_EDITING": {
      return {
        ...state,
        isEditing: false,
        selectedFee: null,
      }
    }
    case "FETCH_FEES": {
      const { data } = action.payload

      return {
        ...state,
        fees: data,
      }
    }
    case "FETCH_COMPANIES": {
      const { data } = action.payload

      return {
        ...state,
        companies: data,
      }
    }
    default:
      return state
  }
}
