import string from "utils/string"
import DecisionTree from "components/LeadModal/components/DecisionTree"
import History from "components/LeadModal/components/History"
import {
  TabItem,
  TabList,
  TabPanel,
  Tabs,
} from "components/LeadModal/components/Tabs"
import {
  Sidebar,
  SidebarContent,
} from "components/LeadModal/components/Sidebar"
import { useLeadModalContext } from "components/LeadModal/context"
import { SPECIAL_CHARS } from "components/LeadModal/components/CopyClipboard/constants"

const Loans = () => {
  const { lead } = useLeadModalContext()

  return (
    <>
      <Sidebar
        title="Dados do cliente"
        name={lead.info.lead_name}
        document={lead.info.document_number}
        variant="full"
      >
        <SidebarContent
          variant="full"
          values={[
            {
              label: "E-mail",
              value: lead.info.email,
            },
            {
              label: "Telefone",
              specialChars: SPECIAL_CHARS.cellphone,
              value: [lead.info.phone_number, lead.info.phone_numbers],
            },
            {
              label: "Forma de contato",
              value: lead.info.contact_type,
            },
            {
              label: "Cidade",
              value: string.concatenate(
                lead.info.city || "ND",
                lead.info.federative_unit || "ND"
              ),
            },
            {
              label: "Valor máximo pré-aprovado",
              value: lead.info.pre_approved_value,
              specialChars: SPECIAL_CHARS.money,
            },
          ]}
        />
        <SidebarContent
          variant="full"
          values={[
            {
              label: "Usuário",
              value: lead.info.user_owner_name,
            },
            {
              label: "Criado na origem em",
              value: lead.info.source_created_at,
            },
            {
              label: "Criado na Pivô em",
              value: lead.info.created_at,
            },
            {
              label: "Atribuído em",
              value: lead.info.user_assigned_at,
            },
            {
              label: "Expira em",
              value: lead.info.expired_at,
            },
          ]}
        />
      </Sidebar>
      <History records={lead.history} />
      <Tabs>
        <TabList>
          <TabItem index="0">Registrar novo contato</TabItem>
          <TabItem index="1">Nova proposta</TabItem>
        </TabList>
        <TabPanel index="0">
          <DecisionTree />
        </TabPanel>
        <TabPanel index="1">
          <iframe
            width="100%"
            height="100%"
            src={lead.iframe.src}
            frameBorder="0"
            title="Nova proposta"
          />
        </TabPanel>
      </Tabs>
    </>
  )
}

export default Loans
