import Card from "components/Card"
import ClientHeader from "components/LeadModal/components/ClientHeader"
import {
  Sidebar,
  SidebarContent,
} from "components/LeadModal/components/Sidebar"
import DecisionTree from "components/LeadModal/components/DecisionTree"
import Header from "components/LeadModal/components/Header"
import History from "components/LeadModal/components/History"
import {
  TabItem,
  TabList,
  TabPanel,
  Tabs,
} from "components/LeadModal/components/Tabs"
import { useLeadModalContext } from "components/LeadModal/context"
import { SPECIAL_CHARS } from "components/LeadModal/components/CopyClipboard/constants"

const Microloans = () => {
  const { lead } = useLeadModalContext()

  return (
    <>
      <Header name={lead.info.lead_name} document={lead.info.document_number} />
      <Tabs>
        <TabList>
          <TabItem index="0">Dados</TabItem>
          <TabItem index="1">Histórico</TabItem>
          <TabItem index="2">Registrar novo contato</TabItem>
          <TabItem index="3">Nova proposta</TabItem>
        </TabList>
        <TabPanel index="0">
          <Card className="modal-lead__card-mobile" spacing="none">
            <ClientHeader
              name={lead.info.lead_name}
              document={lead.info.document_number}
            />
            <Sidebar>
              <SidebarContent
                variant="full"
                values={[
                  {
                    label: "E-mail",
                    value: lead.info.email,
                  },
                  {
                    label: "Telefone",
                    specialChars: SPECIAL_CHARS.cellphone,
                    value: [lead.info.phone_number, lead.info.phone_numbers],
                  },
                  {
                    label: "Forma de contato",
                    value: lead.info.contact_type,
                  },
                  {
                    label: "Perfil",
                    value: lead.info.lead_type,
                  },
                  {
                    label: "Créditos",
                    value: lead.info.credit_quantity,
                  },
                  {
                    label: "Contrato ativo",
                    value: lead.info.active_contract,
                  },
                  {
                    label: "Contrato",
                    value: lead.info.contract,
                  },
                  {
                    label: "Valor máximo pré-aprovado",
                    value: lead.info.approved_value,
                    specialChars: SPECIAL_CHARS.money,
                  },
                  {
                    label: "Valor máximo de parcela",
                    value: lead.info.installments_value,
                    specialChars: SPECIAL_CHARS.money,
                  },
                ]}
              />
              <SidebarContent
                variant="full"
                subtitle="Endereço residencial"
                values={[
                  {
                    label: "Endereço",
                    value: `${lead.info.address_street_name || "ND"}, ${
                      lead.info.address_number || "ND"
                    }`,
                  },
                  {
                    label: "Complemento",
                    value: lead.info.address_complement,
                  },
                  {
                    label: "Bairro",
                    value: lead.info.address_neighborhood,
                  },
                  {
                    label: "CEP",
                    specialChars: SPECIAL_CHARS.remove,
                    value: lead.info.address_postal_code,
                  },
                  {
                    label: "Cidade",
                    value: `${lead.info.address_city || "ND"}, ${
                      lead.info.address_federative_unit || "ND"
                    }`,
                  },
                ]}
              />
              <SidebarContent
                variant="full"
                subtitle="Endereço comercial"
                values={[
                  {
                    label: "Endereço",
                    value: `${
                      lead.info.commercial_address_street_name || "ND"
                    }, ${lead.info.commercial_address_number || "ND"}`,
                  },
                  {
                    label: "Complemento",
                    value: lead.info.commercial_address_complement,
                  },
                  {
                    label: "Bairro",
                    value: lead.info.commercial_address_neighborhood,
                  },
                  {
                    label: "CEP",
                    specialChars: SPECIAL_CHARS.remove,
                    value: lead.info.commercial_address_postal_code,
                  },
                  {
                    label: "Cidade",
                    value: `${lead.info.commercial_address_city || "ND"}, ${
                      lead.info.commercial_address_federative_unit || "ND"
                    }`,
                  },
                ]}
              />
              <SidebarContent
                variant="full"
                values={[
                  {
                    label: "Usuário",
                    value: lead.info.user_owner_name,
                  },
                  {
                    label: "Criado na origem em",
                    value: lead.info.source_created_at,
                  },
                  {
                    label: "Criado na Pivô em",
                    value: lead.info.created_at,
                  },
                  {
                    label: "Atribuído em",
                    value: lead.info.user_assigned_at,
                  },
                  {
                    label: "Expira em",
                    value: lead.info.expired_at,
                  },
                ]}
              />
            </Sidebar>
          </Card>
        </TabPanel>
        <TabPanel index="1">
          <History records={lead.history} />
        </TabPanel>
        <TabPanel index="2">
          <DecisionTree />
        </TabPanel>
        <TabPanel index="3">
          <iframe
            width="100%"
            height="100%"
            src={lead.iframe.src}
            frameBorder="0"
            title="Nova proposta"
          />
        </TabPanel>
      </Tabs>
    </>
  )
}

export default Microloans
