import Icon from "components/Icon"
import CopyClipboard from "../CopyClipboard"
import Text from "components/Typography/Text"
import { SPECIAL_CHARS } from "../CopyClipboard/constants"

import "./ClientHeader.styl"

const ClientHeader = ({ name, document }) => (
  <section className="client-header">
    <Icon className="client-header__icon" name="profile" />
    <div className="client-header__content">
      <CopyClipboard text={name}>
        <Text className="client-header__name">{name}</Text>
      </CopyClipboard>
      <CopyClipboard text={document} specialChars={SPECIAL_CHARS.remove}>
        <Text className="client-header__document">{document}</Text>
      </CopyClipboard>
    </div>
  </section>
)

export default ClientHeader
