import {
  Sidebar,
  SidebarContent,
} from "components/LeadModal/components/Sidebar"
import DecisionTree from "components/LeadModal/components/DecisionTree"
import History from "components/LeadModal/components/History"
import Title from "components/Typography/Title"
import { useLeadModalContext } from "components/LeadModal/context"
import { SPECIAL_CHARS } from "components/LeadModal/components/CopyClipboard/constants"

const CheckingAccount = () => {
  const { lead } = useLeadModalContext()

  return (
    <>
      <Sidebar
        title="Dados do cliente"
        name={lead.info.lead_name}
        document={lead.info.document_number}
        variant="full"
      >
        <SidebarContent
          variant="full"
          values={[
            {
              label: "E-mail",
              value: lead.info.email,
            },
            {
              label: "Telefone",
              specialChars: SPECIAL_CHARS.cellphone,
              value: [lead.info.phone_number, lead.info.phone_numbers],
            },
            {
              label: "Forma de contato",
              value: lead.info.contact_type,
            },
            {
              label: "Tipo do lead",
              value: lead.info.lead_type,
            },
            {
              label: "Trava CIP",
              value: lead.info.has_cip_lock,
            },
            {
              label: "Nome da empresa",
              value: lead.info.employer_name,
            },
            {
              label: "CNPJ do pagador",
              specialChars: SPECIAL_CHARS.remove,
              value: lead.info.employer_document_number,
            },
            {
              label: "Banco concorrente",
              value: lead.info.competitor_bank_name,
            },
            {
              label: "Segmento",
              value: lead.info.segmentation,
            },
            {
              label: "Agência",
              value: lead.info.branch,
            },
            {
              label: "Conta",
              value:
                !!lead.info.account && !!lead.info.digit
                  ? `${lead.info.account}-${lead.info.digit}`
                  : null,
            },
            {
              label: "Nº de protocolo",
              value: lead.info.protocol_number,
            },
            {
              label: "Benefícios vigentes",
              value: lead.info.payroll_offers,
            },
            {
              label: "Novos benefícios",
              value: lead.info.new_offers,
            },
          ]}
        />
        <SidebarContent
          variant="full"
          values={[
            {
              label: "Usuário",
              value: lead.info.user_owner_name,
            },
            {
              label: "Criado na origem em",
              value: lead.info.source_created_at,
            },
            {
              label: "Criado na Pivô em",
              value: lead.info.created_at,
            },
            {
              label: "Atribuído em",
              value: lead.info.user_assigned_at,
            },
            {
              label: "Expira em",
              value: lead.info.expired_at,
            },
          ]}
        />
      </Sidebar>
      <History records={lead.history} />
      <div className="modal-lead__tabs-wrapper">
        <div className="modal-lead__decision-tree">
          <Title variant="small">Registrar novo contato</Title>
          <DecisionTree />
        </div>
      </div>
    </>
  )
}

export default CheckingAccount
