import Input from "components/Forms/Input"
import Radio from "components/Forms/Radio"
import TextArea from "components/Forms/TextArea"
import Select from "components/Forms/Select"
import DateTime from "components/Forms/DateTime"
import date from "utils/date"

export default {
  "datetime-local": ({ onAutoSubmit, item, form, index }) => (
    <DateTime
      name={item.field.name}
      form={form}
      showTime={true}
      onChange={({ currentTarget }) => {
        onAutoSubmit({
          question_order: item.question_order,
          written_answer: date.toDateTimeString(currentTarget.value),
          index,
          hideLoader: true,
        })
      }}
    />
  ),
  textarea: ({ onInputText, item, form, index }) => (
    <TextArea
      name={item.field.name}
      form={form}
      onChange={({ currentTarget }) =>
        onInputText({
          question_order: item.question_order,
          written_answer: currentTarget.value,
          index,
        })
      }
    />
  ),
  select: ({ onAutoSubmit, item, index, form }) => (
    <Select
      name={item.field.name}
      onChange={(event) => {
        onAutoSubmit({
          question_order: item.question_order,
          answer_id: event.currentTarget.value,
          index,
          hideLoader: true,
        })
      }}
      form={form}
    >
      {item?.answer_options?.map((option) => (
        <option value={String(option.answer_id)} key={option.answer_id}>
          {option.answer}
        </option>
      ))}
    </Select>
  ),
  text: ({ onInputText, item, index, form }) => (
    <Input
      mask={item.field.mask}
      name={item.field.name}
      form={form}
      rules={{
        required: {
          value: item.field.required,
          message: "Campo obrigatório",
        },
        validate: (value) => item.field.validation(value) || "Dados inválidos",
      }}
      onChange={({ currentTarget }) => {
        onInputText({
          question_order: item.question_order,
          written_answer: currentTarget.value,
          index,
        })
      }}
    />
  ),
  radio: ({ onAutoSubmit, item, index, form }) => (
    <ul className="form-radio-list">
      {item.answer_options?.map((option) => (
        <li className="form-radio-list__item" key={option.answer_id}>
          <Radio
            name={item.field.name}
            value={String(option.answer_id)}
            label={option.answer}
            form={form}
            onChange={({ currentTarget }) => {
              onAutoSubmit({
                question_order: item.question_order,
                answer_id: currentTarget.value,
                index,
                hideLoader: true,
              })
            }}
          />
        </li>
      ))}
    </ul>
  ),
}
