import string from "utils/string"
import Card from "components/Card"
import ClientHeader from "components/LeadModal/components/ClientHeader"
import {
  Sidebar,
  SidebarContent,
} from "components/LeadModal/components/Sidebar"
import DecisionTree from "components/LeadModal/components/DecisionTree"
import Header from "components/LeadModal/components/Header"
import History from "components/LeadModal/components/History"
import {
  TabItem,
  TabList,
  TabPanel,
  Tabs,
} from "components/LeadModal/components/Tabs"
import { useLeadModalContext } from "components/LeadModal/context"
import { SPECIAL_CHARS } from "components/LeadModal/components/CopyClipboard/constants"

const Consortium = () => {
  const { lead } = useLeadModalContext()

  return (
    <>
      <Header name={lead.info.lead_name} document={lead.info.document_number} />
      <Tabs>
        <TabList>
          <TabItem index="0">Dados</TabItem>
          <TabItem index="1">Histórico</TabItem>
          <TabItem index="2">Registrar novo contato</TabItem>
        </TabList>
        <TabPanel index="0">
          <Card className="modal-lead__card-mobile" spacing="none">
            <ClientHeader
              name={lead.info.lead_name}
              document={lead.info.document_number}
            />
            <Sidebar>
              <SidebarContent
                variant="full"
                values={[
                  {
                    label: "E-mail",
                    value: lead.info.email,
                  },
                  {
                    label: "Telefone",
                    specialChars: SPECIAL_CHARS.cellphone,
                    value: [lead.info.phone_number, lead.info.phone_numbers],
                  },
                  {
                    label: "Categoria",
                    value: lead.info.category,
                  },
                  {
                    label: "Valor do bem",
                    value: lead.info.asset_value,
                    specialChars: SPECIAL_CHARS.money,
                  },
                  {
                    label: "Valor do crédito",
                    value: lead.info.credit_value,
                    specialChars: SPECIAL_CHARS.money,
                  },
                  {
                    label: "Valor da parcela",
                    value: lead.info.installment_value,
                    specialChars: SPECIAL_CHARS.money,
                  },
                  {
                    label: "Prazo",
                    value: lead.info.installment_quantity,
                  },
                  {
                    label: "Código do bem",
                    value: lead.info.asset_code,
                  },
                  {
                    label: "Fundo de reserva",
                    value: lead.info.reserve_fund,
                    specialChars: SPECIAL_CHARS.rate,
                  },
                  {
                    label: "Taxa de administração",
                    value: lead.info.admin_fee,
                    specialChars: SPECIAL_CHARS.rate,
                  },
                ]}
              />
              <SidebarContent
                variant="full"
                values={[
                  {
                    label: "Valor de carta (simulação)",
                    value: lead.info.asset_initial_value,
                    specialChars: SPECIAL_CHARS.money,
                  },
                  {
                    label: "Valor de parcela (simulação)",
                    value: lead.info.installment_initial_value,
                    specialChars: SPECIAL_CHARS.money,
                  },
                  {
                    label: "Urgência",
                    value: lead.info.purchase_time,
                  },
                  {
                    label: "Fluxo backup",
                    value: lead.info.is_bypass,
                  },
                  {
                    label: "Crédito disponível",
                    value: lead.info.has_credit,
                  },
                ]}
              />
              <SidebarContent
                variant="full"
                values={[
                  {
                    label: "Usuário",
                    value: lead.info.user_owner_name,
                  },
                  {
                    label: "Criado na origem em",
                    value: lead.info.source_created_at,
                  },
                  {
                    label: "Criado na Pivô em",
                    value: lead.info.created_at,
                  },
                  {
                    label: "Atribuído em",
                    value: lead.info.user_assigned_at,
                  },
                  {
                    label: "Expira em",
                    value: lead.info.expired_at,
                  },
                ]}
              />
            </Sidebar>
          </Card>
        </TabPanel>
        <TabPanel index="1">
          <History records={lead.history} />
        </TabPanel>
        <TabPanel index="2">
          <DecisionTree />
        </TabPanel>
      </Tabs>
    </>
  )
}

export default Consortium
