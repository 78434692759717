import PopUp from "components/PopUp"

import { useLeadModalContext } from "components/LeadModal/context"

import "./ConfirmPopUp.styl"

const ConfirmPopUp = () => {
  const {
    decisionTree: { unfinished, popup, finishTree, startTree },
  } = useLeadModalContext()

  const isOpen = unfinished && popup

  return (
    <PopUp
      visibility={isOpen}
      title="Fechar janela?"
      className="confirm-popup"
      description="O registro de contato não será salvo"
    >
      <button className="confirm-popup__cancel" onClick={startTree}>
        Cancelar
      </button>
      <button className="confirm-popup__close" onClick={finishTree}>
        Fechar
      </button>
    </PopUp>
  )
}

export default ConfirmPopUp
