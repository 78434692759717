import If from "components/If"
import classnames from "classnames"
import { TabsProvider, useTabsContext } from "./context"

import "./Tabs.styl"

export const TabPanel = ({ index, children, ...props }) => {
  const {
    tabs: { active },
  } = useTabsContext()

  return (
    <If condition={active === index}>
      <div className="tabs__panel" index={index} {...props}>
        {children}
      </div>
    </If>
  )
}

export const TabItem = ({ index, children, ...props }) => {
  const {
    tabs: { active },
    changeTab,
  } = useTabsContext()

  return (
    <li
      className={classnames("tabs__list-item", {
        "tabs__list-item--active": active === index,
      })}
      index={index}
      onClick={() => changeTab(index)}
      {...props}
    >
      {children}
    </li>
  )
}

export const TabList = ({ children, ...props }) => {
  return (
    <nav className="tabs__nav" data-test="nav">
      <ul className="tabs__list" {...props}>
        {children}
      </ul>
    </nav>
  )
}

export const Tabs = ({ children, ...props }) => {
  return (
    <TabsProvider>
      <div className="tabs" {...props}>
        {children}
      </div>
    </TabsProvider>
  )
}
