import Card from "components/Card"
import ClientHeader from "components/LeadModal/components/ClientHeader"
import {
  Sidebar,
  SidebarContent,
} from "components/LeadModal/components/Sidebar"
import DecisionTree from "components/LeadModal/components/DecisionTree"
import Header from "components/LeadModal/components/Header"
import History from "components/LeadModal/components/History"
import {
  TabItem,
  TabList,
  TabPanel,
  Tabs,
} from "components/LeadModal/components/Tabs"
import { useLeadModalContext } from "components/LeadModal/context"
import { SPECIAL_CHARS } from "components/LeadModal/components/CopyClipboard/constants"

const CheckingAccount = () => {
  const { lead } = useLeadModalContext()

  return (
    <>
      <Header name={lead.info.lead_name} document={lead.info.document_number} />
      <Tabs>
        <TabList>
          <TabItem index="0">Dados</TabItem>
          <TabItem index="1">Histórico</TabItem>
          <TabItem index="2">Registrar novo contato</TabItem>
        </TabList>
        <TabPanel index="0">
          <Card className="modal-lead__card-mobile" spacing="none">
            <ClientHeader
              name={lead.info.lead_name}
              document={lead.info.document_number}
            />
            <Sidebar>
              <SidebarContent
                variant="full"
                values={[
                  {
                    label: "E-mail",
                    value: lead.info.email,
                  },
                  {
                    label: "Telefone",
                    specialChars: SPECIAL_CHARS.cellphone,
                    value: [lead.info.phone_number, lead.info.phone_numbers],
                  },
                  {
                    label: "Forma de contato",
                    value: lead.info.contact_type,
                  },
                  {
                    label: "Tipo do lead",
                    value: lead.info.lead_type,
                  },
                  {
                    label: "Trava CIP",
                    value: lead.info.has_cip_lock,
                  },
                  {
                    label: "Nome da empresa",
                    value: lead.info.employer_name,
                  },
                  {
                    label: "CNPJ do pagador",
                    specialChars: SPECIAL_CHARS.remove,
                    value: lead.info.employer_document_number,
                  },
                  {
                    label: "Banco concorrente",
                    value: lead.info.competitor_bank_name,
                  },
                  {
                    label: "Segmento",
                    value: lead.info.segmentation,
                  },
                  {
                    label: "Agência",
                    value: lead.info.branch,
                  },
                  {
                    label: "Conta",
                    value:
                      !!lead.info.account && !!lead.info.digit
                        ? `${lead.info.account}-${lead.info.digit}`
                        : null,
                  },
                  {
                    label: "Nº de protocolo",
                    value: lead.info.protocol_number,
                  },
                  {
                    label: "Benefícios vigentes",
                    value: lead.info.payroll_offers,
                  },
                  {
                    label: "Novos benefícios",
                    value: lead.info.new_offers,
                  },
                ]}
              />
              <SidebarContent
                variant="full"
                values={[
                  {
                    label: "Usuário",
                    value: lead.info.user_owner_name,
                  },
                  {
                    label: "Criado na origem em",
                    value: lead.info.source_created_at,
                  },
                  {
                    label: "Criado na Pivô em",
                    value: lead.info.created_at,
                  },
                  {
                    label: "Atribuído em",
                    value: lead.info.user_assigned_at,
                  },
                  {
                    label: "Expira em",
                    value: lead.info.expired_at,
                  },
                ]}
              />
            </Sidebar>
          </Card>
        </TabPanel>
        <TabPanel index="1">
          <History records={lead.history} />
        </TabPanel>
        <TabPanel index="2">
          <DecisionTree />
        </TabPanel>
      </Tabs>
    </>
  )
}

export default CheckingAccount
