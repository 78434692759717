import Card from "components/Card"
import formatters from "utils/formatters"
import ClientHeader from "components/LeadModal/components/ClientHeader"
import {
  Sidebar,
  SidebarContent,
} from "components/LeadModal/components/Sidebar"
import DecisionTree from "components/LeadModal/components/DecisionTree"
import Header from "components/LeadModal/components/Header"
import History from "components/LeadModal/components/History"
import {
  TabItem,
  TabList,
  TabPanel,
  Tabs,
} from "components/LeadModal/components/Tabs"
import AddFee from "../components/AddFee"
import { useLeadModalContext } from "components/LeadModal/context"
import { SPECIAL_CHARS } from "components/LeadModal/components/CopyClipboard/constants"

const Rede = () => {
  const { lead } = useLeadModalContext()

  return (
    <>
      <Header name={lead.info.lead_name} document={lead.info.document_number} />
      <Tabs>
        <TabList>
          <TabItem index="0">Dados</TabItem>
          <TabItem index="1">Histórico</TabItem>
          <TabItem index="2">Registrar novo contato</TabItem>
        </TabList>
        <TabPanel index="0">
          <Card className="modal-lead__card-mobile" spacing="none">
            <ClientHeader
              name={lead.info.lead_name}
              document={lead.info.document_number}
            />
            <Sidebar>
              <SidebarContent
                variant="full"
                values={[
                  {
                    label: "E-mail",
                    value: lead.info.email,
                  },
                  {
                    label: "Telefone",
                    specialChars: SPECIAL_CHARS.cellphone,
                    value: [lead.info.phone_number, lead.info.phone_numbers],
                  },
                  {
                    label: "Forma de contato",
                    value: lead.info.contact_type,
                  },
                  {
                    label: "Telefone comercial",
                    specialChars: SPECIAL_CHARS.cellphone,
                    value: formatters.toPhone(lead.info.business_phone_number, {
                      nullableReturn: true,
                    }),
                  },
                  {
                    label: "Nome da empresa",
                    value: lead.info.commercial_name,
                  },
                  {
                    label: "Faturamento",
                    value: lead.info.revenues_range,
                  },
                  {
                    label: "Preferência de horário para contato",
                    value: lead.info.available_time_range,
                  },
                  {
                    label: "Origem",
                    value: lead.info.source,
                  },
                  {
                    label: "Último campo",
                    value: lead.info.last_step,
                  },

                  {
                    label: "Peça de mídia",
                    value: lead.info.media_campaign,
                  },
                ]}
              />
              <SidebarContent
                variant="full"
                values={[
                  {
                    label: "Usuário",
                    value: lead.info.user_owner_name,
                  },
                  {
                    label: "Criado na origem em",
                    value: lead.info.source_created_at,
                  },
                  {
                    label: "Criado na Pivô em",
                    value: lead.info.created_at,
                  },
                  {
                    label: "Atribuído em",
                    value: lead.info.user_assigned_at,
                  },
                  {
                    label: "Expira em",
                    value: lead.info.expired_at,
                  },
                ]}
              />
              <AddFee />
            </Sidebar>
          </Card>
        </TabPanel>
        <TabPanel index="1">
          <History records={lead.history} />
        </TabPanel>
        <TabPanel index="2">
          <DecisionTree />
        </TabPanel>
      </Tabs>
    </>
  )
}

export default Rede
