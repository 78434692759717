import { useCallback, useEffect, useRef, useState } from "react"

import services from "services"
import { values as formValues } from "./form"

const useAddFeeForm = ({ business_id, lead_uuid, reducer }) => {
  const othersId = "0"
  const otherCompanyValueRef = useRef("")
  const [isUpdate, setIsUpdate] = useState(false)
  const [values, setValues] = useState(formValues)
  const [canShowOther, setCanShowOther] = useState(false)
  const [isCompanyValid, setIsCompanyValid] = useState(true)

  const handleButton = useCallback(() => {
    reducer.dispatch({
      type: "CANCEL_EDITING",
    })
  }, [reducer])

  const validateIfCanShowOtherCompany = useCallback((company_id) => {
    const canShow = String(company_id) === String(othersId)
    setCanShowOther(canShow)

    if (canShow) {
      setIsCompanyValid(!!otherCompanyValueRef.current)
    }
  }, [])

  const onChangeCompany = useCallback(
    (company_field) => {
      validateIfCanShowOtherCompany(company_field.target.value)
    },
    [validateIfCanShowOtherCompany]
  )

  const onChangeOtherCompany = useCallback((other_company_field) => {
    otherCompanyValueRef.current =
      other_company_field.target.value?.toLowerCase()

    setIsCompanyValid(!!otherCompanyValueRef.current)
  }, [])

  const toFeeSelectedId = useCallback(
    (fee) =>
      fee.lead_fee_offer_id !== reducer.state?.selectedFee?.lead_fee_offer_id,
    [reducer.state.selectedFee]
  )

  const handleFeeUpdated = useCallback(
    (fee_updated) => {
      if (!fee_updated?.error) {
        reducer.dispatch({
          type: "FETCH_FEES",
          payload: {
            data: [...reducer.state.fees.filter(toFeeSelectedId), fee_updated],
            business_id,
          },
        })

        handleButton()
      }
    },
    [business_id, reducer, toFeeSelectedId, handleButton]
  )

  const handleNewFee = useCallback(
    (new_fee) => {
      if (!new_fee?.error) {
        reducer.dispatch({
          type: "FETCH_FEES",
          payload: {
            data: [...reducer.state.fees, new_fee],
            business_id,
          },
        })

        handleButton()
      }
    },
    [business_id, reducer, handleButton]
  )

  const onSubmit = useCallback(
    (formData) => {
      if (!formData?.competitor_company_id) return

      if (isUpdate) {
        return services.feeOfeer.fee
          .update({
            lead_uuid,
            business_id,
            payload: formData,
            fee_offer_id: reducer.state?.selectedFee?.lead_fee_offer_id,
          })
          .then(handleFeeUpdated)
      }

      services.feeOfeer.fee
        .create({ business_id, lead_uuid, payload: formData })
        .then(handleNewFee)
    },
    [
      isUpdate,
      lead_uuid,
      business_id,
      handleNewFee,
      handleFeeUpdated,
      reducer.state.selectedFee,
    ]
  )

  const setFormToUpdate = useCallback(() => {
    const isEditingAndHasValueFromAPI =
      reducer.state.isEditing && !!reducer.state.selectedFee

    if (isEditingAndHasValueFromAPI) {
      setIsCompanyValid(true)
      setIsUpdate(true)
      setValues({
        ...reducer.state.selectedFee,
        competitor_company_id:
          reducer.state.selectedFee?.competitor_company?.id,
      })

      otherCompanyValueRef.current =
        reducer.state.selectedFee?.custom_company_name || ""
      validateIfCanShowOtherCompany(
        reducer.state.selectedFee?.competitor_company?.id
      )
    }
  }, [
    reducer.state.isEditing,
    reducer.state.selectedFee,
    validateIfCanShowOtherCompany,
  ])

  useEffect(() => {
    setFormToUpdate()
  }, [setFormToUpdate])

  return {
    values,
    onSubmit,
    othersId,
    canShowOther,
    handleButton,
    isCompanyValid,
    onChangeCompany,
    onChangeOtherCompany,
    companyList: reducer.state.companies,
  }
}

export default useAddFeeForm
