import { createContext, useContext, useState } from "react"

const TabsContext = createContext()

export const TabsProvider = ({ children }) => {
  const [tabs, setTabs] = useState({
    active: "0",
  })

  const changeTab = (index) => {
    setTabs({ active: index })
  }

  return (
    <TabsContext.Provider value={{ tabs, changeTab }}>
      {children}
    </TabsContext.Provider>
  )
}

export const useTabsContext = () => useContext(TabsContext)
