import Card from "components/Card"
import ClientHeader from "components/LeadModal/components/ClientHeader"
import {
  Sidebar,
  SidebarContent,
} from "components/LeadModal/components/Sidebar"
import DecisionTree from "components/LeadModal/components/DecisionTree"
import Header from "components/LeadModal/components/Header"
import History from "components/LeadModal/components/History"
import {
  TabItem,
  TabList,
  TabPanel,
  Tabs,
} from "components/LeadModal/components/Tabs"
import { useLeadModalContext } from "components/LeadModal/context"
import { SPECIAL_CHARS } from "components/LeadModal/components/CopyClipboard/constants"

const Vehicles = () => {
  const { lead } = useLeadModalContext()

  return (
    <>
      <Header name={lead.info.lead_name} document={lead.info.document_number} />
      <Tabs>
        <TabList>
          <TabItem index="0">Dados</TabItem>
          <TabItem index="1">Histórico</TabItem>
          <TabItem index="2">Registrar novo contato</TabItem>
        </TabList>
        <TabPanel index="0">
          <Card className="modal-lead__card-mobile" spacing="none">
            <ClientHeader
              name={lead.info.lead_name}
              document={lead.info.document_number}
            />
            <Sidebar>
              <SidebarContent
                variant="full"
                values={[
                  {
                    label: "Telefone",
                    specialChars: SPECIAL_CHARS.cellphone,
                    value: lead.info.phone_numbers,
                  },
                  {
                    label: "E-mail",
                    value: lead.info.email,
                  },
                  {
                    label: "Data da simulação",
                    value: lead.info.simulation_date,
                  },
                  {
                    label: "Período para compra",
                    value: lead.info.purchase_period,
                  },
                  {
                    label: "Fonte do lead",
                    value: lead.info.source,
                  },
                  {
                    label: "Id da simulação",
                    value: lead.info.vehicle_trim_id,
                  },
                  {
                    label: "Modelo do veículo",
                    value: lead.info.vehicle_model,
                  },
                  {
                    label: "Ano do veículo",
                    value: lead.info.vehicle_model_year,
                  },
                  {
                    label: "Valor de entrada",
                    value: lead.info.entry_value,
                    specialChars: SPECIAL_CHARS.money,
                  },
                  {
                    label: "Quantidade de parcelas",
                    value: lead.info.installment_amount,
                  },
                  {
                    label: "Valor da parcela",
                    value: lead.info.installment_value,
                    specialChars: SPECIAL_CHARS.money,
                  },
                  {
                    label: "Valor de venda",
                    value: lead.info.vehicle_price,
                    specialChars: SPECIAL_CHARS.money,
                  },
                ]}
              />
              <SidebarContent
                variant="full"
                values={[
                  {
                    label: "Usuário",
                    value: lead.info.user_owner_name,
                  },
                  {
                    label: "Criado na origem em",
                    value: lead.info.source_created_at,
                  },
                  {
                    label: "Criado na Pivô em",
                    value: lead.info.created_at,
                  },
                  {
                    label: "Atribuído em",
                    value: lead.info.user_assigned_at,
                  },
                  {
                    label: "Expira em",
                    value: lead.info.expired_at,
                  },
                ]}
              />
            </Sidebar>
          </Card>
        </TabPanel>
        <TabPanel index="1">
          <History records={lead.history} />
        </TabPanel>
        <TabPanel index="2">
          <DecisionTree />
        </TabPanel>
      </Tabs>
    </>
  )
}

export default Vehicles
