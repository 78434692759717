export const REMOVAL_TIME = 2000

export const SPECIAL_CHARS = {
  default: {
    except: "",
    removeToo: "",
    hasToRemove: false,
  },
  remove: {
    except: "",
    removeToo: "",
    hasToRemove: true,
  },
  cellphone: {
    except: "",
    removeToo: "\\s",
    hasToRemove: true,
  },
  money: {
    except: ",",
    removeToo: "R\\s", //"R "
    hasToRemove: true,
  },
  rate: {
    except: ",",
    removeToo: "",
    hasToRemove: true,
  },
}
