import EmptyState from "components/EmptyState"
import If from "components/If"
import Text from "components/Typography/Text"
import Title from "components/Typography/Title"

import "./History.styl"

const History = ({ records }) => (
  <div className="history" data-test="history">
    <Title variant="small">Histórico</Title>
    <div className="history__content">
      <If
        condition={records?.length}
        renderIf={
          <ul className="history__list">
            {records?.map((record, index) => (
              <li className="history__item" key={index}>
                <Text>{record.history_title}</Text>
                <If condition={record.history_description}>
                  <p className="history__description">
                    {record.history_description}
                  </p>
                </If>
                <If condition={record.observations}>
                  <p className="history__description">{record.observations}</p>
                </If>
                <span className="history__date">
                  {record.created_at_date} às {record.created_at_hour}
                </span>
                <span className="history__user-owner">
                  {record.user_owner_name}
                </span>
              </li>
            ))}
          </ul>
        }
        renderElse={<EmptyState text="Nada por aqui!" />}
      />
    </div>
  </div>
)

export default History
