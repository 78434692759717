import If from "components/If"
import ModalLayer from "./components/ModalLayer"
import Variant from "./components/Variant"
import { LeadModalProvider, useLeadModalContext } from "./context"
import { Types } from "./constants"

import "./LeadModal.styl"

const Wrapper = ({ children }) => {
  const {
    modal: { opened },
    type,
  } = useLeadModalContext()

  switch (type) {
    case Types.Modal:
      return (
        <If condition={opened}>
          <ModalLayer>{children}</ModalLayer>
        </If>
      )
    case Types.Component:
      return <section className="component-lead">{children}</section>
    default:
      return <>{children}</>
  }
}

const Content = () => {
  const { business } = useLeadModalContext()

  const Variants = {
    conta_corrente: <Variant.CheckingAccount />,
    microcredito: <Variant.Microloans />,
    consignado: <Variant.Consignment />,
    consortium: <Variant.Consortium />,
    crediario: <Variant.Loans />,
    recovery: <Variant.Recovery />,
    veiculos: <Variant.Vehicles />,
    rede: <Variant.Rede />,
    fgts: <Variant.FGTS />,
  }
  return Variants[business.business_name]
}

const LeadModal = ({
  type = "modal",
  viewport = "none",
  active,
  leadUUID,
  business = {},
  onClose = (e) => e,
  onSubmit = (e) => e,
}) => (
  <LeadModalProvider
    type={type}
    viewport={viewport}
    active={active}
    leadUUID={leadUUID}
    business={business}
    onClose={onClose}
    onSubmit={onSubmit}
  >
    <Wrapper>
      <Content />
    </Wrapper>
  </LeadModalProvider>
)

export default LeadModal
