import classNames from "classnames"
import { transformInObjectIfValid } from "./utils"

import "./Radio.styl"

const Radio = ({
  name,
  label,
  value,
  onChange,
  checked = null,
  form: { register },
  variant = "default",
  ...props
}) => (
  <div
    className={classNames("form-radio", {
      [`form-radio__variant--${variant}`]: !!variant,
    })}
  >
    <label className="form-radio__control">
      <input
        className="form-radio__button"
        type="radio"
        value={value}
        {...props}
        {...register(name, { onChange })}
        {...transformInObjectIfValid({ property: checked, name: "checked" })}
      />
      <span className="form-radio__label">{label}</span>
    </label>
  </div>
)

export default Radio
