import If from "components/If"
import maskers from "utils/maskers"
import { validation } from "./form"
import useAddFeeForm from "./hooks"
import Button from "components/Button"
import Form from "components/Forms/Form"
import Input from "components/Forms/Input"
import Select from "components/Forms/Select"

import "./Form.styl"

const AddFeeForm = ({ lead_uuid, business_id, reducer }) => {
  const {
    values,
    onSubmit,
    companyList,
    canShowOther,
    handleButton,
    isCompanyValid,
    onChangeCompany,
    onChangeOtherCompany,
  } = useAddFeeForm({ lead_uuid, business_id, reducer })

  return (
    <Form
      manually={true}
      reinitialize={true}
      onSubmit={onSubmit}
      schema={validation}
      defaultValues={values}
      className="add-fee-form"
    >
      {({ form, state: { isValid } }) => (
        <>
          <Select
            form={form}
            label="Adquirente"
            onChange={onChangeCompany}
            placeholder="Selecione..."
            name="competitor_company_id"
            className="add-fee-form__select"
            aria-label="Pesquisar adquirente"
          >
            {companyList.map((company) => (
              <option key={company.id} value={company.id}>
                {company.name}
              </option>
            ))}
          </Select>
          <If condition={canShowOther}>
            <Input
              form={form}
              label="Outro"
              name="custom_company_name"
              className="add-fee-form__input"
              onChange={onChangeOtherCompany}
            />
          </If>
          <Input
            form={form}
            placeholder="0,00%"
            mask={maskers.percent}
            name="cash_credit_rate"
            className="add-fee-form__input"
            label="Taxa de Crédito à vista"
          />
          <Input
            form={form}
            placeholder="0,00%"
            mask={maskers.percent}
            name="credit_rate_from_2_to_6"
            className="add-fee-form__input"
            label="Taxa de Crédito parcelado (2-6x)"
          />
          <Input
            form={form}
            placeholder="0,00%"
            mask={maskers.percent}
            name="credit_rate_from_7_to_12"
            className="add-fee-form__input"
            label="Taxa de Crédito parcelado (7-12x)"
          />
          <Input
            form={form}
            placeholder="0,00%"
            mask={maskers.percent}
            name="anticipation_rate"
            label="Taxa de Antecipação"
            className="add-fee-form__input"
          />
          <Input
            form={form}
            label="Aluguel"
            placeholder="R$ 0,00"
            name="machine_rental"
            mask={maskers.currency}
            className="add-fee-form__input"
          />

          <Button
            onClick={onSubmit}
            disabled={!isValid || !isCompanyValid}
            className="add-fee-form__button--submit"
          >
            Enviar
          </Button>
          <Button
            onClick={handleButton}
            className="add-fee-form__button--cancel"
          >
            Cancelar
          </Button>
        </>
      )}
    </Form>
  )
}

export default AddFeeForm
