import If from "components/If"
import Hint from "components/Hint"
import useCopyClipboard from "./hooks"
import { SPECIAL_CHARS } from "./constants"

import "./CopyClipboard.styl"

const CopyClipboard = ({
  text,
  children,
  specialChars = SPECIAL_CHARS.default,
  ...props
}) => {
  const { copyItem, isShowingHint, textToCopy } = useCopyClipboard({
    specialChars,
    text,
  })

  return (
    <div className="copy-clipboard" {...props}>
      <div
        className="copy-clipboard__content"
        onClick={(e) => copyItem(e, textToCopy)}
      >
        {children}
      </div>
      <If condition={isShowingHint}>
        <div className="copy-clipboard__hint--container">
          <Hint
            hasAutoWidth
            isSideways={true}
            horizontal="right"
            className="copy-clipboard__hint"
          >
            Copiado
          </Hint>
        </div>
      </If>
    </div>
  )
}

export default CopyClipboard
