import CheckingAccount from "./CheckingAccount"
import Consignment from "./Consignment"
import Consortium from "./Consortium"
import Microloans from "./Microloans"
import Recovery from "./Recovery"
import Vehicles from "./Vehicles"
import Loans from "./Loans"
import Rede from "./Rede"
import FGTS from "./FGTS"

export default {
  CheckingAccount,
  Consignment,
  Consortium,
  Microloans,
  Recovery,
  Vehicles,
  Loans,
  Rede,
  FGTS,
}
