import { useCallback, useEffect, useState } from "react"
import formatters from "utils/formatters"

import { REMOVAL_TIME } from "./constants"

const useCopyClipboard = ({ specialChars, text }) => {
  const [textToCopy, setTextToCopy] = useState("")
  const [isShowingHint, setIsShowingHint] = useState(false)

  const handleTextToCopy = useCallback(() => {
    const hasToRemoveSpecialChars = !!specialChars?.hasToRemove
    const textFormatted = hasToRemoveSpecialChars
      ? formatters.removeSpecialChars(
          String(text),
          specialChars?.except,
          specialChars?.removeToo
        )
      : text
    setTextToCopy(textFormatted)
  }, [
    text,
    specialChars.except,
    specialChars.removeToo,
    specialChars.hasToRemove,
  ])

  const copyItem = (event, value) => {
    event.preventDefault()

    navigator.clipboard.writeText(value)
    showHint()
  }

  const showHint = () => {
    setIsShowingHint(true)

    setTimeout(() => setIsShowingHint(false), REMOVAL_TIME)
  }

  useEffect(() => {
    handleTextToCopy()
  }, [handleTextToCopy])

  return { copyItem, isShowingHint, textToCopy }
}

export default useCopyClipboard
