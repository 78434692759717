import CopyClipboard from "../CopyClipboard"

/*
 * Nesta função verificamos se é um array, fundimos todos os arrays internos, removemos valores falsos e duplicados
 * e por fim retornamos os valores separados por uma quebra de linha
 */
export const getMultipleItems = (value = [], specialChars) =>
  Array.isArray(value) &&
  value
    ?.flat()
    ?.filter((val, i, self) => val && self.indexOf(val) === i)
    ?.map((val, i) => (
      <CopyClipboard text={val} key={i} specialChars={specialChars}>
        <dd className="sidebar__value">{val}</dd>
      </CopyClipboard>
    ))

export const getHasValidValues = (values) =>
  values?.reduce((prev, current) => !!current?.value || prev, false)
