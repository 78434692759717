import Modal from "components/Modal"
import { useLeadModalContext } from "../../context"

const ModalLayer = ({ children }) => {
  const {
    modal: { closeModal },
  } = useLeadModalContext()

  return (
    <Modal
      className="modal-lead"
      closeModal={closeModal}
      data-test="modal-lead"
    >
      {children}
    </Modal>
  )
}

export default ModalLayer
