import { useLeadModalContext } from "components/LeadModal/context"
import { Desktop, Mobile } from "components/Devices"
import DesktopScreen from "./Desktop"
import MobileScreen from "./Mobile"

const Viewports = {
  desktop: DesktopScreen,
  mobile: MobileScreen,
}

const Microloans = () => {
  const { viewport } = useLeadModalContext()
  const Screen = Viewports[viewport]

  if (Screen) return <Screen />

  return (
    <>
      <Mobile>
        <MobileScreen />
      </Mobile>
      <Desktop>
        <DesktopScreen />
      </Desktop>
    </>
  )
}
export default Microloans
