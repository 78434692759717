import { useState } from "react"

const useAccordion = ({ fee, reducer }) => {
  const [isOpen, setIsOpen] = useState(false)

  const editFee = () => {
    reducer.dispatch({
      type: "START_EDITING",
      payload: {
        selectedFee: fee,
      },
    })
  }

  return {
    isOpen,
    editFee,
    toggleAccordion: () => setIsOpen((prev) => !prev),
  }
}

export default useAccordion
