import If from "components/If"
import CopyClipboard from "../CopyClipboard"
import Text from "components/Typography/Text"
import Title from "components/Typography/Title"
import { SPECIAL_CHARS } from "../CopyClipboard/constants"

import "./Header.styl"

const Header = ({ name, document }) => {
  return (
    <header className="modal-header">
      <If condition={name}>
        <CopyClipboard text={name}>
          <Title className="modal-header__name" variant="small">
            {name}
          </Title>
        </CopyClipboard>
      </If>
      <If condition={document}>
        <CopyClipboard text={document} specialChars={SPECIAL_CHARS.remove}>
          <Text className="modal-header__document" variant="small">
            {document}
          </Text>
        </CopyClipboard>
      </If>
    </header>
  )
}

export default Header
