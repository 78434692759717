import If from "components/If"
import classnames from "classnames"
import CopyClipboard from "../CopyClipboard"
import Text from "components/Typography/Text"
import Title from "components/Typography/Title"
import Subtitle from "components/Typography/Subtitle"
import { SPECIAL_CHARS } from "../CopyClipboard/constants"
import { getHasValidValues, getMultipleItems } from "./utils"

import "./Sidebar.styl"

export const Sidebar = ({ title, name, document, variant, children }) => (
  <aside className="sidebar">
    <Title className="sidebar__title" variant="small">
      {title}
    </Title>
    <div
      className={classnames("sidebar__container", {
        [`sidebar--${variant}`]: variant,
      })}
    >
      <header className="sidebar__header">
        <If condition={name}>
          <CopyClipboard text={name}>
            <Title className="sidebar__name" variant="small">
              {name}
            </Title>
          </CopyClipboard>
        </If>
        <If condition={document}>
          <CopyClipboard text={document} specialChars={SPECIAL_CHARS.remove}>
            <Text className="sidebar__document" variant="small">
              {document}
            </Text>
          </CopyClipboard>
        </If>
      </header>
      {children}
    </div>
  </aside>
)

export const SidebarContent = ({ values, subtitle, variant }) => {
  const hasValidValues = getHasValidValues(values)

  return (
    <If condition={hasValidValues}>
      <div
        className={classnames("sidebar__content", {
          [`sidebar__content--${variant}`]: variant,
        })}
      >
        <If condition={subtitle}>
          <Subtitle>{subtitle}</Subtitle>
        </If>
        {values?.map((item, index) => (
          <If condition={item?.value} key={index}>
            <dl className="sidebar__list">
              <dt className="sidebar__label">{item?.label}</dt>
              <If
                condition={Array.isArray(item?.value)}
                renderIf={getMultipleItems(item?.value, item?.specialChars)}
                renderElse={
                  <CopyClipboard
                    text={item?.value}
                    specialChars={item?.specialChars}
                  >
                    <dd className="sidebar__value">{item?.value}</dd>
                  </CopyClipboard>
                }
              />
            </dl>
          </If>
        ))}
      </div>
    </If>
  )
}
