import {
  Sidebar,
  SidebarContent,
} from "components/LeadModal/components/Sidebar"
import DecisionTree from "components/LeadModal/components/DecisionTree"
import History from "components/LeadModal/components/History"
import Title from "components/Typography/Title"
import { useLeadModalContext } from "components/LeadModal/context"
import { SPECIAL_CHARS } from "components/LeadModal/components/CopyClipboard/constants"

const Vehicles = () => {
  const { lead } = useLeadModalContext()
  return (
    <>
      <Sidebar
        title="Dados do cliente"
        name={lead.info.lead_name}
        document={lead.info.document_number}
        variant="full"
      >
        <SidebarContent
          variant="full"
          values={[
            {
              label: "Telefone",
              specialChars: SPECIAL_CHARS.cellphone,
              value: lead.info.phone_numbers,
            },
            {
              label: "E-mail",
              value: lead.info.email,
            },
            {
              label: "Data da simulação",
              value: lead.info.simulation_date,
            },
            {
              label: "Período para compra",
              value: lead.info.purchase_period,
            },
            {
              label: "Fonte do lead",
              value: lead.info.source,
            },
            {
              label: "Id da simulação",
              value: lead.info.vehicle_trim_id,
            },
            {
              label: "Modelo do veículo",
              value: lead.info.vehicle_model,
            },
            {
              label: "Ano do veículo",
              value: lead.info.vehicle_model_year,
            },
            {
              label: "Valor de entrada",
              value: lead.info.entry_value,
              specialChars: SPECIAL_CHARS.money,
            },
            {
              label: "Quantidade de parcelas",
              value: lead.info.installment_amount,
            },
            {
              label: "Valor da parcela",
              value: lead.info.installment_value,
              specialChars: SPECIAL_CHARS.money,
            },
            {
              label: "Valor de venda",
              value: lead.info.vehicle_price,
              specialChars: SPECIAL_CHARS.money,
            },
          ]}
        />
        <SidebarContent
          variant="full"
          values={[
            {
              label: "Usuário",
              value: lead.info.user_owner_name,
            },
            {
              label: "Criado na origem em",
              value: lead.info.source_created_at,
            },
            {
              label: "Criado na Pivô em",
              value: lead.info.created_at,
            },
            {
              label: "Atribuído em",
              value: lead.info.user_assigned_at,
            },
            {
              label: "Expira em",
              value: lead.info.expired_at,
            },
          ]}
        />
      </Sidebar>
      <History records={lead.history} />
      <div className="modal-lead__tabs-wrapper">
        <div className="modal-lead__decision-tree">
          <Title variant="small">Registrar novo contato</Title>
          <DecisionTree />
        </div>
      </div>
    </>
  )
}

export default Vehicles
