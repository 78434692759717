import string from "utils/string"
import {
  Sidebar,
  SidebarContent,
} from "components/LeadModal/components/Sidebar"
import Title from "components/Typography/Title"
import History from "components/LeadModal/components/History"
import DecisionTree from "components/LeadModal/components/DecisionTree"
import { SPECIAL_CHARS } from "components/LeadModal/components/CopyClipboard/constants"

import { useLeadModalContext } from "components/LeadModal/context"

const Consignment = () => {
  const { lead } = useLeadModalContext()

  return (
    <>
      <Sidebar
        title="Dados do cliente"
        name={lead.info.lead_name}
        document={lead.info.document_number}
        variant="full"
      >
        <SidebarContent
          variant="full"
          values={[
            {
              label: "Nome do gerente",
              value: lead.info.manager_name,
            },
            {
              label: "Tipo de contato",
              value: lead.info.signing_type,
            },
            {
              label: "Código da loja",
              value: lead.info.store_code,
            },
            {
              label: "E-mail",
              value: lead.info.email,
            },
            {
              label: "Telefone",
              specialChars: SPECIAL_CHARS.cellphone,
              value: [lead.info.phone_number, lead.info.phone_numbers],
            },
            {
              label: "Período de atendimento",
              value: lead.info.available_time_range,
            },
            {
              label: "Observações",
              value: lead.info.extra_information,
            },
            {
              label: "Tipo de produto",
              value: lead.info.product_type,
            },
            {
              label: "Convênio",
              value: lead.info.government_name,
            },
            {
              label: "Tipo de oferta",
              value: lead.info.offer_type,
            },
            {
              label: "Forma de contato",
              value: lead.info.contact_type,
            },
            {
              label: "Tipo do lead",
              value: lead.info.lead_type,
            },
            {
              label: "Origem",
              value: lead.info.source,
            },
            {
              label: "Público alvo",
              value: lead.info.audience,
            },
            {
              label: "Fonte de renda",
              value: lead.info.occupation,
            },
            {
              label: "Valor solicitado",
              value: lead.info.desired_loan_value,
              specialChars: SPECIAL_CHARS.money,
            },
            {
              label: "Uso do dinheiro",
              value: lead.info.purpose,
            },
            {
              label: "Número do benefício",
              value: lead.info.benefit_number,
            },
            {
              label: "UF do benefício",
              value: lead.info.benefit_fu,
            },
            {
              label: "Valor liberado",
              value: lead.info.released_value,
              specialChars: SPECIAL_CHARS.money,
            },
            {
              label: "Quantidade total de parcelas",
              value: lead.info.total_installments_number,
            },
            {
              label: "Valor da parcela da simulação",
              value: lead.info.installment_value_inss,
              specialChars: SPECIAL_CHARS.money,
            },
            {
              label: "Taxa efetiva mensal",
              value: lead.info.effective_monthly_rate,
            },
            {
              label: "Imposto de operação financeira",
              value: lead.info.financial_operation_tax,
            },
            {
              label: "Valor total financiado",
              value: lead.info.total_financed_value,
              specialChars: SPECIAL_CHARS.money,
            },
          ]}
        />
        <SidebarContent
          variant="full"
          values={[
            {
              label: "Matrícula do servidor",
              value: lead.info.employee_id,
            },
            {
              label: "Número IPSEMG",
              value: lead.info.ipsemg_number,
            },
            {
              label: "Data de nascimento",
              value: lead.info.birthdate,
            },
            {
              label: "Valor da renda",
              value: lead.info.income_value,
              specialChars: SPECIAL_CHARS.money,
            },
            {
              label: "Valor da margem disponivel",
              value: lead.info.available_value,
              specialChars: SPECIAL_CHARS.money,
            },
          ]}
        />
        <SidebarContent
          variant="full"
          values={[
            {
              label: "Saldo devedor na origem",
              value: lead.info.origin_in_arrear_value,
              specialChars: SPECIAL_CHARS.money,
            },
            {
              label: "Valor da parcela na origem",
              value: lead.info.origin_installment_value,
              specialChars: SPECIAL_CHARS.money,
            },
            {
              label: "Total de parcelas na origem",
              value: lead.info.origin_installments_number,
            },
            {
              label: "Total de parcelas pagas na origem",
              value: lead.info.origin_installments_paid_number,
            },
            {
              label: "Número contrato na origem",
              value: lead.info.origin_contract_number,
            },
            {
              label: "Data do último vencimento na origem",
              value: lead.info.origin_last_due_date,
            },
            {
              label: "Data do primeiro vencimento na origem",
              value: lead.info.origin_first_due_date,
            },
            {
              label: "Taxa do contrato na origem",
              value: lead.info.origin_contract_rate,
              specialChars: SPECIAL_CHARS.rate,
            },
            {
              label: "Banco origem",
              value: lead.info.origin_bank_code,
            },
          ]}
        />
        <SidebarContent
          variant="full"
          values={[
            {
              label: "Banco para credito",
              value: lead.info.bank_code,
            },
            {
              label: "Agência para crédito",
              value: lead.info.branch,
            },
            {
              label: "Conta para crédito",
              value: lead.info.account,
            },
          ]}
        />
        <SidebarContent
          variant="full"
          values={[
            {
              label: "Taxa da oferta",
              value: lead.info.offer_rate,
              specialChars: SPECIAL_CHARS.rate,
            },
            {
              label: "Tabela de fator",
              value: lead.info.factor_table,
            },
            {
              label: "Saldo refinanciado simulação",
              value: lead.info.refinanced_balance,
              specialChars: SPECIAL_CHARS.money,
            },
            {
              label: "Valor solicitado ou troco",
              value: lead.info.requested_value,
              specialChars: SPECIAL_CHARS.money,
            },
            {
              label: "Valor da parcela da simulacão",
              value: lead.info.installment_value,
              specialChars: SPECIAL_CHARS.money,
            },
            {
              label: "Prazo da simulacao",
              value: lead.info.installments_number,
            },
            {
              label: "Data do primeiro vencimento",
              value: lead.info.first_due_date,
            },
            {
              label: "Data do último vencimento",
              value: lead.info.last_due_date,
            },
          ]}
        />
        <SidebarContent
          variant="full"
          values={[
            {
              label: "Nome da Mãe",
              value: lead.info.mother_name,
            },
            {
              label: "Nome do Pai",
              value: lead.info.father_name,
            },
            {
              label: "Naturalidade",
              value: lead.info.place_of_birth,
            },
            {
              label: "Nacionalidade",
              value: lead.info.nationality,
            },
            {
              label: "Tipo de documento",
              value: lead.info.additional_document_type,
            },
            {
              label: "Número do documento",
              value: lead.info.additional_document_number,
              specialChars: SPECIAL_CHARS.remove,
            },
            {
              label: "Órgão emissor",
              value: lead.info.additional_document_issuer,
            },
            {
              label: "UF do documento",
              value: lead.info.additional_document_federative_unit,
            },
            {
              label: "Data de emissão do documento",
              value: lead.info.additional_document_issue_date,
            },
          ]}
        />
        <SidebarContent
          variant="full"
          values={[
            {
              label: "CEP",
              value: lead.info.postal_code,
              specialChars: SPECIAL_CHARS.remove,
            },
            {
              label: "Endereço",
              value: string.concatenate(
                lead.info.street_name,
                lead.info.number
              ),
            },
            {
              label: "Complemento",
              value: lead.info.complement,
            },
            {
              label: "Bairro",
              value: lead.info.neighborhood,
            },
            {
              label: "Cidade",
              value: lead.info.city_and_fu,
            },
          ]}
        />
        <SidebarContent
          variant="full"
          values={[
            {
              label: "Usuário",
              value: lead.info.user_owner_name,
            },
            {
              label: "Criado na origem em",
              value: lead.info.source_created_at,
            },
            {
              label: "Criado na Pivô em",
              value: lead.info.created_at,
            },
            {
              label: "Atribuído em",
              value: lead.info.user_assigned_at,
            },
            {
              label: "Expira em",
              value: lead.info.expired_at,
            },
            {
              label: "Funcional do gerente",
              value: lead.info.manager_itau_identification,
            },
          ]}
        />
      </Sidebar>
      <History records={lead.history} />
      <div className="modal-lead__tabs-wrapper">
        <div className="modal-lead__decision-tree">
          <Title variant="small">Registrar novo contato</Title>
          <DecisionTree />
        </div>
      </div>
    </>
  )
}

export default Consignment
