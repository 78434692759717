import { useCallback, useEffect, useState } from "react"

import services from "services"
import _object from "utils/object"
import debounce from "utils/debounce"
import { useLeadModalContext } from "components/LeadModal/context"
import { buildHTMLField, toNextSubmit, toCheckAnswers } from "./utils"

const useDecisionTree = () => {
  const {
    lead: { fetchLeadDetails, ...lead },
    decisionTree: { unfinished, startTree, resetTree, onSubmitTree },
    business,
  } = useLeadModalContext()

  const [answers, setAnswers] = useState([])
  const [decisions, setDecisions] = useState({
    current: null,
    tree: [],
  })

  const handleSubmittedForm = ({ error, resetForm }) => {
    if (!error) {
      const leadUUID = lead.info.lead_uuid

      setDecisions({
        current: null,
        tree: [],
      })

      fetchDecisionTree({
        business_id: business.business_id,
        contact_type: lead.info?.contact_type,
      })
      fetchLeadDetails({
        lead_uuid: leadUUID,
        business_id: business.business_id,
        business_name: business.business_name,
      })

      onSubmitTree()
      resetTree()
      resetForm()
    }
  }

  const onAutoSubmit = ({
    question_order,
    written_answer,
    hideLoader,
    answer_id,
    index,
  }) => {
    if (!unfinished) {
      startTree()
    }

    setAnswers((prevState) => {
      const submittedIndex = index + 1
      const filterDuplicateOrderByIndex = prevState.filter(
        (item) => item.index < submittedIndex
      )

      const payload = [
        ...filterDuplicateOrderByIndex,
        {
          question_order,
          written_answer,
          answer_id,
          index: submittedIndex,
        },
      ]

      return payload
    })

    fetchDecisionTree({
      contact_type: lead.info?.contact_type,
      business_id: business.business_id,
      question_order,
      answer_id,
      index,
      hideLoader,
    })
  }

  const onInputText = debounce(({ question_order, written_answer, index }) => {
    onAutoSubmit({
      question_order,
      written_answer,
      index,
      hideLoader: true,
    })
  }, 1000)

  const onSubmit = (formData, event, { reset, defaultValues }) => {
    const contactType = lead.info?.contact_type
    const leadUUID = lead.info.lead_uuid

    services.bases.form
      .answers({
        business_id: business.business_id,
        contact_type: contactType,
        lead_uuid: leadUUID,
        formData,
        answers,
      })
      .then((data) =>
        handleSubmittedForm({
          errors: data,
          resetForm: () => reset(defaultValues),
        })
      )
  }

  const buildDecision = (payload) => {
    return {
      ...payload,
      field: buildHTMLField(payload),
    }
  }

  const buildDecisionTree = useCallback(
    ({ payload, submittedIndex, answer_id, field_id }) => {
      const decision = buildDecision({ ...payload, field_id })

      setDecisions(({ tree }) => ({
        confirm: null,
        current: decision,
        tree: [
          ...tree
            .reduce(
              (acc, curr, index) =>
                toNextSubmit({ acc, curr, submittedIndex, index }),
              []
            )
            .map((item, index) =>
              toCheckAnswers({
                item,
                index,
                answer_id,
                submittedIndex,
              })
            ),
          decision,
        ],
      }))
    },
    []
  )

  const fetchDecisionTree = useCallback(
    (payload) => {
      services.bases.form.questions(payload).then((data) => {
        buildDecisionTree({
          payload: data,
          submittedIndex: payload.index,
          answer_id: payload.answer_id,
          field_id: _object.generateUuid(),
        })
      })
    },
    [buildDecisionTree]
  )

  useEffect(() => {
    fetchDecisionTree({
      business_id: business.business_id,
      contact_type: lead.info?.contact_type,
    })
  }, [business.business_id, fetchDecisionTree, lead.info.contact_type])

  return {
    decisions,
    onSubmit,
    onInputText,
    onAutoSubmit,
  }
}

export default useDecisionTree
