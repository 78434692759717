import string from "utils/string"
import {
  Sidebar,
  SidebarContent,
} from "components/LeadModal/components/Sidebar"
import DecisionTree from "components/LeadModal/components/DecisionTree"
import History from "components/LeadModal/components/History"
import Title from "components/Typography/Title"
import { useLeadModalContext } from "components/LeadModal/context"
import { SPECIAL_CHARS } from "components/LeadModal/components/CopyClipboard/constants"

const FGTS = () => {
  const { lead } = useLeadModalContext()

  return (
    <>
      <Sidebar
        title="Dados do cliente"
        name={lead.info.lead_name}
        document={lead.info.document_number}
        variant="full"
      >
        <SidebarContent
          variant="full"
          values={[
            {
              label: "E-mail",
              value: lead.info.email,
            },
            {
              label: "Telefone",
              specialChars: SPECIAL_CHARS.cellphone,
              value: [lead.info.phone_number, lead.info.phone_numbers],
            },
            {
              label: "Forma de contato",
              value: lead.info.contact_type,
            },
            {
              label: "Origem",
              value: lead.info.source,
            },
            {
              label: "Cidade",
              value:
                lead.info.city || lead.info.federative_unit
                  ? string.concatenate(
                      lead.info.city || "ND",
                      lead.info.federative_unit || "ND"
                    )
                  : undefined,
            },
            {
              label: "CEP",
              value: lead.info.zip_code,
              specialChars: SPECIAL_CHARS.remove,
            },
            {
              label: "Tipo do lead",
              value: lead.info.lead_type,
            },
            {
              label: "Valor liberado",
              value: lead.info.available_value,
              specialChars: SPECIAL_CHARS.money,
            },
            {
              label: "Quantidade de parcelas",
              value: lead.info.installments_number,
            },
            {
              label: "Taxa mensal",
              value: lead.info.rate_monthly,
              specialChars: SPECIAL_CHARS.rate,
            },
            {
              label: "CET anual máxima",
              value: lead.info.max_annual_tec,
              specialChars: SPECIAL_CHARS.rate,
            },
            {
              label: "Dados de antecipação",
              value: lead.info?.anticipation_data,
            },
          ]}
        />
        <SidebarContent
          variant="full"
          values={[
            {
              label: "Usuário",
              value: lead.info.user_owner_name,
            },
            {
              label: "Criado na origem em",
              value: lead.info.source_created_at,
            },
            {
              label: "Criado na Pivô em",
              value: lead.info.created_at,
            },
            {
              label: "Atribuído em",
              value: lead.info.user_assigned_at,
            },
            {
              label: "Expira em",
              value: lead.info.expired_at,
            },
          ]}
        />
      </Sidebar>
      <History records={lead.history} />
      <div className="modal-lead__tabs-wrapper">
        <div className="modal-lead__decision-tree">
          <Title variant="small">Registrar novo contato</Title>
          <DecisionTree />
        </div>
      </div>
    </>
  )
}

export default FGTS
