import Button from "components/Button"
import If from "components/If"
import TextArea from "components/Forms/TextArea"
import Subtitle from "components/Typography/Subtitle"
import useDecisionTree from "./hooks"
import Form from "components/Forms/Form"
import ConfirmPopUp from "./components/ConfirmPopUp"
import Fields from "./components/Fields"

import { values } from "./form"

import "./DecisionTree.styl"

const DecisionTree = () => {
  const { decisions, onSubmit, onInputText, onAutoSubmit } = useDecisionTree()

  return (
    <Form
      className="decision-tree"
      defaultValues={values}
      onSubmit={onSubmit}
      manually={true}
    >
      {({ form, state: { isDisabled } }) => (
        <>
          <div className="decision-tree__questions">
            {decisions.tree.map((item, index) => (
              <section className="decision-tree__question" key={index}>
                <Subtitle className="decision-tree__subtitle">
                  {item?.question}
                  <If condition={item.required}>
                    <span className="decision-tree__required">*</span>
                  </If>
                </Subtitle>
                {item.field.variant &&
                  Fields[item.field.variant]({
                    item,
                    index,
                    form,
                    onAutoSubmit,
                    onInputText,
                  })}
                <If condition={item.finish_form}>
                  <Subtitle>Observações</Subtitle>
                  <TextArea name="observations" form={form} maxlength="450" />
                </If>
              </section>
            ))}
          </div>
          <footer className="decision-tree__footer">
            <Button
              type="submit"
              size="medium"
              disabled={!decisions?.current?.finish_form || isDisabled}
            >
              Salvar
            </Button>
          </footer>
          <ConfirmPopUp />
        </>
      )}
    </Form>
  )
}

export default DecisionTree
