import Button from "components/Button"
import Icon from "components/Icon"
import useAccordion from "./hooks"
import If from "components/If"

import "./Accordion.styl"
import classNames from "classnames"

const Accordion = ({ fee, reducer }) => {
  const { isOpen, editFee, toggleAccordion } = useAccordion({ fee, reducer })

  return (
    <>
      <div className="add-fee-accordion">
        <div
          onClick={toggleAccordion}
          className="add-fee-accordion__title"
          aria-label={`Accordion Title: ${
            fee?.competitor_company?.name || fee?.custom_company_name
          }`}
        >
          {fee?.competitor_company?.name || fee?.custom_company_name}

          <Icon
            className={classNames("add-fee-accordion__title--icon", {
              "add-fee-accordion__title--icon--open": isOpen,
            })}
            name="arrow-down"
          />
        </div>

        <div
          className={classNames("add-fee-accordion__content", {
            "add-fee-accordion__content--open": isOpen,
          })}
        >
          <div
            onClick={toggleAccordion}
            className="add-fee-accordion__field--list"
          >
            <div className="add-fee-accordion__field">
              <span className="add-fee-accordion__field--label">
                Taxa de Crédito à vista
              </span>
              <span className="add-fee-accordion__field--data">
                {fee?.cash_credit_rate || "ND"}
              </span>
            </div>

            <div className="add-fee-accordion__field">
              <span className="add-fee-accordion__field--label">
                Taxa de Crédito parcelado (2-6x)
              </span>
              <span className="add-fee-accordion__field--data">
                {fee?.credit_rate_from_2_to_6 || "ND"}
              </span>
            </div>

            <div className="add-fee-accordion__field">
              <span className="add-fee-accordion__field--label">
                Taxa de Crédito parcelado (7-12x)
              </span>
              <span className="add-fee-accordion__field--data">
                {fee?.credit_rate_from_7_to_12 || "ND"}
              </span>
            </div>

            <div className="add-fee-accordion__field">
              <span className="add-fee-accordion__field--label">
                Taxa de Antecipação
              </span>
              <span className="add-fee-accordion__field--data">
                {fee?.anticipation_rate || "ND"}
              </span>
            </div>

            <div className="add-fee-accordion__field">
              <span className="add-fee-accordion__field--label">Aluguel</span>
              <span className="add-fee-accordion__field--data">
                {fee?.machine_rental || "ND"}
              </span>
            </div>
          </div>

          <Button onClick={editFee} className="add-fee-accordion__button--edit">
            Editar
          </Button>
        </div>
      </div>
    </>
  )
}

export default Accordion
