import getContractNumber from "../utils"
import {
  Sidebar,
  SidebarContent,
} from "components/LeadModal/components/Sidebar"
import DecisionTree from "components/LeadModal/components/DecisionTree"
import History from "components/LeadModal/components/History"
import {
  TabItem,
  TabList,
  TabPanel,
  Tabs,
} from "components/LeadModal/components/Tabs"
import { useLeadModalContext } from "components/LeadModal/context"
import { SPECIAL_CHARS } from "components/LeadModal/components/CopyClipboard/constants"
import Icon from "components/Icon"
import "../Contracts.styl"

const Recovery = () => {
  const { lead } = useLeadModalContext()

  return (
    <>
      <Sidebar
        title="Dados do cliente"
        name={lead.info.lead_name}
        document={lead.info.document_number}
        variant="full"
      >
        <SidebarContent
          variant="full"
          values={[
            {
              label: "E-mail",
              value: lead.info.email,
            },
            {
              label: "Telefone",
              specialChars: SPECIAL_CHARS.cellphone,
              value: [lead.info.phone_number, lead.info.phone_numbers],
            },
            {
              label: "Data de nascimento",
              value: lead.info.birthdate,
            },
            {
              label: "Tipo de cliente",
              value: lead.info.lead_type,
            },
          ]}
        />

        {lead.info?.debt_groups?.map((debt) => (
          <>
            <SidebarContent
              variant="full"
              values={[
                {
                  label: "Produto",
                  value: debt.debt_product,
                },
                {
                  label: "Tipo de dívida",
                  value: debt.debt_type,
                },
                {
                  label: "Grupo de dívida",
                  value: debt.group_name,
                },
                {
                  label: "Fundo credor",
                  value: debt.fund_name,
                },
                {
                  label: "Valor original da dívida",
                  specialChars: SPECIAL_CHARS.money,
                  value: debt.original_debt_value,
                },
                {
                  label: "Valor atual da dívida",
                  specialChars: SPECIAL_CHARS.money,
                  value: debt.min_current_debt_value,
                },
                {
                  label: "Desconto",
                  specialChars: SPECIAL_CHARS.rate,
                  value: debt.discount_percentage,
                },
              ]

                .flat()
                .flat()}
            />
            <div className="lead-modal-contracts__container">
              {debt?.contracts?.map((contract, index) => (
                <details className="lead-modal-contracts__details">
                  <summary
                    key={`contracts-${index}`}
                    className="lead-modal-contracts__title"
                  >
                    Contrato #{getContractNumber(index)}
                    <Icon
                      className="lead-modal-contracts__arrow"
                      name="arrow-down"
                    />
                  </summary>

                  <div className="lead-modal-contracts__content">
                    <SidebarContent
                      variant="full"
                      values={[
                        {
                          label: "Número do contrato",
                          value: contract?.contract_number,
                        },
                        {
                          label: "Produto",
                          value: contract?.debt_product,
                        },
                        {
                          label: "Subproduto",
                          value: contract?.debt_sub_product,
                        },
                        {
                          label: "Data da dívida",
                          value: contract?.debt_date,
                        },
                      ]}
                    />
                  </div>
                </details>
              ))}
            </div>
          </>
        ))}

        <SidebarContent
          variant="full"
          values={[
            {
              label: "Usuário",
              value: lead.info.user_owner_name,
            },
            {
              label: "Criado na origem em",
              value: lead.info.source_created_at,
            },
            {
              label: "Criado na Pivô em",
              value: lead.info.created_at,
            },
            {
              label: "Atribuído em",
              value: lead.info.user_assigned_at,
            },
            {
              label: "Expira em",
              value: lead.info.expired_at,
            },
          ]}
        />
      </Sidebar>
      <History records={lead.history} />
      <Tabs>
        <TabList>
          <TabItem index="0">Registrar novo contato</TabItem>
          <TabItem index="1">Nova proposta</TabItem>
        </TabList>
        <TabPanel index="0">
          <DecisionTree />
        </TabPanel>
        <TabPanel index="1">
          <iframe
            width="100%"
            height="100%"
            src={lead.iframe.src}
            frameBorder="0"
            title="Nova proposta"
          />
        </TabPanel>
      </Tabs>
    </>
  )
}

export default Recovery
