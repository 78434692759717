import maskers from "utils/maskers"
import validators from "utils/validators"

const TYPES = {
  written: [
    "text",
    "cpf-cnpj",
    "phone",
    "email",
    "currency",
    "textarea",
    "datetime-local",
  ],
  answer: ["radio", "select"],
  observations: ["observations"],
}

const VALIDATORS = {
  "cpf-cnpj": validators.document,
  phone: validators.phone,
  email: validators.email,
  default: () => true,
}

const MASKERS = {
  "cpf-cnpj": maskers.document,
  phone: maskers.phone,
  currency: maskers.currency,
}

export const buildHTMLField = ({
  question_order,
  answer_type,
  answer_validation_type,
  required,
  field_id,
}) => {
  const type = Object.entries(TYPES).reduce((result, [key, values]) => {
    return values.indexOf(answer_type) !== -1 ? key : result
  }, "")

  const name = `${type}-field-${field_id}`

  const validation = VALIDATORS[answer_validation_type] || VALIDATORS["default"]

  const mask = MASKERS[answer_validation_type] || null

  const order = question_order

  return {
    type,
    variant: answer_type,
    name,
    validation,
    mask,
    order,
    required,
  }
}

export const toNextSubmit = ({ acc, curr, index, submittedIndex }) => {
  const isNext = index < +submittedIndex + 1
  if (isNext) acc.push(curr)
  return acc
}

export const toCheckAnswers = ({ item, index, submittedIndex, answer_id }) => {
  const hasAnswers =
    Number(index) === Number(submittedIndex) && item.answer_options

  if (hasAnswers) {
    item.answer_options.map(
      (answer) =>
        (answer.checked = Number(answer.answer_id) === Number(answer_id))
    )
  }

  return item
}
