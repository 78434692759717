import If from "components/If"
import Icon from "components/Icon"
import { useAddFee } from "./hooks"
import AddFeeForm from "./components/Form"
import Accordion from "./components/Accordion"

import "./AddFee.styl"

const AddFee = () => {
  const { isEditing, onInsertClick, feeList, reducer, lead_uuid, business_id } =
    useAddFee()

  return (
    <section className="add-fee">
      <If condition={isEditing || (!isEditing && !!feeList.length)}>
        <span className="add-fee__title">Taxas adquirente</span>
      </If>

      <If
        condition={isEditing}
        renderIf={
          <AddFeeForm
            reducer={reducer}
            lead_uuid={lead_uuid}
            business_id={business_id}
          />
        }
        renderElse={
          <>
            <div className="add-fee__list">
              {feeList.map((fee, index) => (
                <Accordion
                  fee={fee}
                  reducer={reducer}
                  key={"Accordion_" + index}
                />
              ))}
            </div>

            <button onClick={onInsertClick} className="add-fee__button--insert">
              Adicionar novo adquirente
              <Icon name="add-outlined" />
            </button>
          </>
        }
      />
    </section>
  )
}

export default AddFee
