import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import services from "services"
import scroll from "utils/scroll"
import useCan from "hooks/useCan"
import { Types } from "./constants"
import { getLeadModalType } from "./utils"

const LeadModalContext = createContext()

const LeadModalProvider = ({
  type,
  viewport,
  active,
  leadUUID,
  business,
  children,
  onClose,
  onSubmit,
}) => {
  const [lead, setLead] = useState({ info: {}, history: [], iframe: {} })
  const [modal, setModal] = useState({ opened: false })
  const [decisionTree, setDecisionTree] = useState({
    unfinished: false,
    popup: false,
  })
  const { allowed } = useCan({
    subject: [business.business_permission],
    action: ["sell"],
  })

  const resetTree = () => {
    setDecisionTree({ unfinished: false, popup: false })
  }

  const finishTree = () => {
    resetTree()
    closeModal(true)
  }

  const startTree = () => {
    setDecisionTree({ unfinished: true, popup: false })
  }

  const showTreePopup = () => {
    setDecisionTree((tree) => ({ ...tree, popup: true }))
  }

  const closeModal = (force = false) => {
    if (force || !decisionTree.unfinished) {
      if (Types.Modal === getLeadModalType(type)) {
        scroll.body.enable()
      }

      setModal({
        opened: false,
      })
      onClose()
    } else {
      showTreePopup()
    }
  }

  const fetchLeadDetails = useCallback(
    async ({ lead_uuid, business_id }) => {
      if (Types.Modal === getLeadModalType(type)) {
        scroll.body.disabled()
      }

      const { lead: lead_info, lead_history } = await services.bases.lead({
        lead_uuid,
        business_id,
      })

      const [iframe] = allowed
        ? await services.bases.iframe({ business_id })
        : [{}]

      setModal({
        opened: true,
      })
      setLead({ info: lead_info, history: lead_history, iframe })
    },
    [type, allowed]
  )

  useEffect(() => {
    if (active) {
      fetchLeadDetails({
        lead_uuid: leadUUID,
        business_id: business.business_id,
        business_name: business.business_name,
      })
    }
  }, [active, leadUUID, business, fetchLeadDetails])

  return (
    <LeadModalContext.Provider
      value={{
        lead: {
          ...lead,
          fetchLeadDetails,
          _setLead: setLead,
        },
        modal: {
          ...modal,
          closeModal,
          _setModal: setModal,
        },
        decisionTree: {
          ...decisionTree,
          startTree,
          finishTree,
          resetTree,
          onSubmitTree: onSubmit,
        },
        business,
        type: getLeadModalType(type),
        viewport,
      }}
    >
      {children}
    </LeadModalContext.Provider>
  )
}

const useLeadModalContext = () => useContext(LeadModalContext)

export { LeadModalProvider, useLeadModalContext }
